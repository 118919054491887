<template>
  <div style="overflow: hidden">
    <v-btn
      fab
      dark
      color="#c83635ee"
      class="scrollup-btn"
      v-if="showScrollup"
      @click="scrollup"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <div class="header-bg">
      <section id="header">
        <div class="banner"></div>
        <!-- web -->
        <v-card
          class="wow fadeIn rounded-0 py-2 py-md-7 px-4 px-md-8 ms-8 ms-md-0 card-slogan d-none d-sm-block"
          data-wow-delay="0.1s"
        >
          <p
            class="text-h4 font-weight-bold mt-5 card-slogan-title"
            style="color: #c83635"
          >
            {{ $t("company") }}<br />
            <span class="text-h4 font-weight-bold" v-if="$i18n.locale === 'en'">
              {{ $t("company-1") }}
            </span>
          </p>

          <div class="my-7">
            <span class="text-h6 subtitle-1 font-weight-bold"
              ><span style="color: #c83635">{{ $t("slogan-1") }}</span
              ><span class="blue--text text--darken-4 pe-4">{{
                $t("slogan-2")
              }}</span>
              <span style="color: #c83635">{{ $t("slogan-3") }}</span
              ><span class="blue--text text--darken-4">{{
                $t("slogan-4")
              }}</span></span
            ><br />
            <span
              class="text-h6 subtitle-1 font-weight-medium font-italic blue--text text--darken-4"
              >Connecting the <span style="color: #c83635">world</span>,
              Delivering your <span style="color: #c83635">success</span></span
            >
          </div>
        </v-card>
        <!-- h5 -->
        <v-card
          class="wow fadeIn rounded-0 px-4 card-slogan-h5 d-flex d-sm-none"
          data-wow-delay="0.1s"
        >
          <div class="my-5">
            <div class="text-end">
              <span class="text-h5 font-weight-bold font-italic"
                ><span style="color: #fb8c00">{{ $t("slogan-1") }}</span
                ><span class="white--text text--darken-4 pe-2">{{
                  $t("slogan-2")
                }}</span>
                <span style="color: #fb8c00">{{ $t("slogan-3") }}</span
                ><span class="white--text text--darken-4">{{
                  $t("slogan-4")
                }}</span></span
              ><br />
            </div>
            <div class="text-end">
              <span
                class="font-italic font-weight-bold white--text text--darken-4 text-caption"
                >Connecting the
                <span class="pe-3" style="color: #fb8c00">world</span>
                Delivering your <span style="color: #fb8c00">success</span>
              </span>
            </div>
          </div>
        </v-card>
      </section>
    </div>
    <!-- web -->
    <v-toolbar
      color="#C83635"
      dark
      absolute
      fixed
      class="mt-4 d-none d-md-flex"
      ref="navbar"
    >
      <div class="white py-1 cursor-pointer">
        <v-img
          src="../assets/logo.png"
          width="180"
          height="56px"
          class="mx-2"
        ></v-img>
      </div>
      <v-toolbar-title class="ms-5">
        <v-row>
          <v-col cols="3">
            <v-btn
              text
              class="text-subtitle-1"
              :class="{ active: activeButton === 'about' }"
              @click="scrollToTarget('about')"
              @click.native="activeButton = 'about'"
              ref="aboutBtn"
              >{{ $t("about") }}</v-btn
            >
          </v-col>
          <v-col cols="3">
            <v-btn
              text
              class="text-subtitle-1"
              :class="{ active: activeButton === 'service' }"
              @click="scrollToTarget('service')"
              @click.native="activeButton = 'service'"
              ref="serviceBtn"
              >{{ $t("service") }}</v-btn
            >
          </v-col>
          <v-col cols="3">
            <v-btn
              text
              class="text-subtitle-1 px-2"
              :class="{ active: activeButton === 'org' }"
              @click="scrollToTarget('org')"
              @click.native="activeButton = 'org'"
              ref="orgBtn"
              >{{ $t("org") }}</v-btn
            >
          </v-col>
          <v-col cols="3">
            <v-btn
              text
              class="px-2 text-subtitle-1"
              :class="{ active: activeButton === 'contact' }"
              @click="scrollToTarget('contact')"
              @click.native="activeButton = 'contact'"
              ref="contactBtn"
              >{{ $t("contact") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-img
            class="ms-4 me-4 cusor-pointer"
            :src="languageIcon"
            max-width="32"
            height="auto"
            contain
            v-bind="attrs"
            v-on="on"
          ></v-img>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(item, index) in languageList"
            :key="index"
            @click="changeLanguage(index)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <!-- phone -->
    <v-app-bar app color="#C83635" dark class="d-md-none">
      <v-container>
        <div class="d-flex justify-space-between">
          <v-toolbar-title class="white--text">
            <v-img
              src="../assets/logo.png"
              max-width="156"
              height="auto"
              class="mr-2"
            ></v-img>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                class="me-1"
                :src="languageIcon"
                max-width="32"
                height="auto"
                contain
                v-bind="attrs"
                v-on="on"
              ></v-img>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(item, index) in languageList"
                :key="index"
                @click="changeLanguage(index)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="white--text px-2"> mdi-menu </v-icon>
            </template>
            <v-list>
              <v-list-item @click="scrollToTarget('about')">
                <v-list-item-title>{{ $t("about") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="scrollToTarget('service')">
                <v-list-item-title>{{ $t("service") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="scrollToTarget('org')">
                <v-list-item-title>{{ $t("org") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="scrollToTarget('contact')">
                <v-list-item-title>{{ $t("contact") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-container>
    </v-app-bar>
    <div></div>
    <div class="main-bg">
      <section id="about">
        <v-container class="py-16">
          <h1
            class="wow fadeInLeft text-h4 font-weight-bold mb-16 text-start"
            data-wow-delay="0.1s"
          >
            {{ $t("about") }}
            <span class="text-h6 title-decorate" style="color: #c83635">■</span>
          </h1>
          <v-row class="d-flex align-center justify-space-around mt-10 mb-16">
            <!-- 左邊的照片區塊 -->
            <v-col cols="12" md="7" class="pe-lg-15 pe-3">
              <div class="swiper wow fadeInLeft">
                <div class="swiper-wrapper">
                  <div class="swiper-slide swiper-slide--one"></div>
                  <div class="swiper-slide swiper-slide--two"></div>
                  <div class="swiper-slide swiper-slide--three"></div>
                </div>
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
              </div>
            </v-col>
            <!-- 右邊的公司介紹區塊 -->
            <v-col cols="12" md="5" class="mt-10 mt-md-0">
              <v-timeline dense class="pt-10s">
                <v-timeline-item
                  color="#C83635"
                  small
                  class="mb-10 d-flex align-start"
                >
                  <v-row class="wow fadeInRight">
                    <v-col cols="12">
                      <span slot="opposite">{{ $t("about_1998") }}</span>
                    </v-col>
                    <v-col cols="11">
                      <p>
                        {{ $t("about_1998_content") }}
                      </p>
                    </v-col>
                  </v-row>
                </v-timeline-item>
                <v-timeline-item
                  color="#C83635"
                  small
                  class="d-flex align-start"
                >
                  <v-row class="wow fadeInRight" data-wow-delay="0.1s">
                    <v-col cols="12">
                      <span slot="opposite"> {{ $t("about_2008") }}</span>
                    </v-col>
                    <v-col cols="11">
                      <p>
                        {{ $t("about_2008_content") }}
                      </p>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section id="service">
        <v-container>
          <h1
            class="wow fadeInLeft text-h4 font-weight-bold mb-0 mb-md-16 pt-12 text-start"
            data-wow-delay="0.1s"
          >
            {{ $t("service") }}
            <span
              class="text-h6 title-decorate d-md-none"
              style="color: #c83635"
              >■</span
            >
          </h1>
          <span
            class="text-h6 ps-2 title-decorate-vertical d-none d-md-flex"
            style="color: #c83635"
            >■</span
          >
          <v-row
            class="d-flex align-center justify-space-around mt-md-16 pb-16"
          >
            <!-- 左邊的內容區塊 -->
            <v-col cols="12" md="4">
              <div class="ms-3 mt-16">
                <p class="wow fadeInDown">
                  <span
                    class="text-h6 font-weight-bold"
                    style="color: #c83635"
                    >{{ $t("service_1") }}</span
                  ><br />
                  <span>{{ $t("service_1_content") }}</span>
                </p>
                <p class="wow fadeInDown" data-wow-delay="0.2s">
                  <span
                    class="text-h6 font-weight-bold"
                    style="color: #c83635"
                    >{{ $t("service_2") }}</span
                  ><br />
                  <span>{{ $t("service_2_content") }}</span>
                </p>
              </div>
              <div class="ms-3 mt-10">
                <span class="text-h6" style="color: #c83635">■</span>
                <p
                  class="wow fadeInDown font-weight-black font-italic text-h5 text-decoration-underline"
                  style="color: #c83635"
                  data-wow-delay="0.2s"
                >
                  {{ $t("service_3") }}<br />
                  {{ $t("service_4") }}
                </p>
              </div>
            </v-col>
            <!-- 右邊的服務介紹 -->
            <v-col cols="12" md="8" class="mt-16 mt-md-0">
              <!-- web -->
              <div class="card-service d-none d-md-flex">
                <div class="wow fadeInDown card-service-item cusor-pointer">
                  <p
                    class="pa-2 px-3 text-h6 card-service-subtitle-bottom"
                    style="background-color: #c83635; color: white"
                  >
                    {{ $t("service_title_fly") }}
                  </p>
                </div>
                <div class="wow fadeInUp card-service-item cusor-pointer">
                  <p
                    class="pa-2 px-3 text-h6 card-service-subtitle"
                    style="background-color: #c83635; color: white"
                  >
                    {{ $t("service_title_importAndexport") }}
                  </p>
                </div>
                <div class="wow fadeInDown card-service-item cusor-pointer">
                  <p
                    class="pa-2 px-3 text-h6 card-service-subtitle-bottom"
                    style="background-color: #c83635; color: white"
                  >
                    {{ $t("service_title_logistics") }}
                  </p>
                </div>
                <div class="wow fadeInUp card-service-item cusor-pointer">
                  <p
                    class="pa-2 px-3 text-h6 card-service-subtitle"
                    style="background-color: #c83635; color: white"
                  >
                    {{ $t("service_title_warehousing") }}
                  </p>
                </div>
              </div>
              <!-- h5 -->
              <v-row class="d-md-none">
                <v-col cols="12" sm="6" class="wow fadeInDown">
                  <v-card class="rounded-0">
                    <v-img
                      src="../assets/service-pic1.jpg"
                      height="250px"
                    ></v-img>
                    <v-card-title
                      style="background-color: #c83635; color: white"
                    >
                      {{ $t("service_title_fly") }}
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" class="wow fadeInUp">
                  <v-card
                    class="rounded-0 mt-0 mt-lg-8 d-flex flex-column flex-lg-column-reverse"
                  >
                    <v-img
                      src="../assets/service-pic2.jpg"
                      height="250px"
                    ></v-img>
                    <v-card-title
                      style="background-color: #c83635; color: white"
                    >
                      {{ $t("service_title_importAndexport") }}
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" class="wow fadeInDown">
                  <v-card class="rounded-0">
                    <v-img
                      src="../assets/service-pic3.jpg"
                      height="250px"
                    ></v-img>
                    <v-card-title
                      style="background-color: #c83635; color: white"
                    >
                      {{ $t("service_title_logistics") }}
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" class="wow fadeInUp">
                  <v-card
                    class="rounded-0 mt-0 mt-lg-8 d-flex flex-column flex-lg-column-reverse"
                  >
                    <v-img
                      src="../assets/service-pic4.jpg"
                      height="250px"
                    ></v-img>
                    <v-card-title
                      style="background-color: #c83635; color: white"
                    >
                      {{ $t("service_title_warehousing") }}
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </div>
    <div class="org-bg">
      <section id="org">
        <v-container class="py-16">
          <h1
            class="wow fadeInLeft text-h4 font-weight-bold text-start white--text mb-16"
            data-wow-delay="0.1s"
          >
            {{ $t("org") }}
            <span class="text-h6 title-decorate-org" style="color: #fffff"
              >■</span
            >
          </h1>
          <div class="org-contain d-flex flex-column flex-md-row mt-5">
            <v-row no-gutters>
              <v-col class="wow fadeInLeft col-12 col-md-3 ms-md-16 me-2">
                <v-card class="rounded-0 mb-10 mb-md-0 me-5 me-md-3">
                  <v-card-title
                    class="org-subtitle-bg white--text font-weight-bold d-inline-block"
                    dark
                  >
                    {{ $t("domestic") }}
                  </v-card-title>
                  <v-row no-gutters>
                    <v-col col="6">
                      <v-img
                        src="../assets/pic/org/logo-IOFFLAT.png"
                        height="160px"
                        max-width="150px"
                        contain
                        class="mx-auto"
                      ></v-img>
                    </v-col>
                    <v-divider vertical class="my-10"></v-divider>
                    <v-col col="6">
                      <v-img
                        src="../assets/pic/org/Group 67.png"
                        height="160px"
                        max-width="150px"
                        contain
                        class="mx-auto"
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col class="wow fadeInRight col-12 col-md-6 ms-md-16 ms-0">
                <v-card class="rounded-0 mb-10 mb-md-0 me-5 me-md-3">
                  <v-card-title
                    class="org-subtitle-bg white--text font-weight-bold d-inline-block px-5"
                    dark
                  >
                    {{ $t("overseas") }}
                  </v-card-title>
                  <v-row no-gutters>
                    <v-col class="col-6 col-lg-3">
                      <v-img
                        src="../assets/pic/org/logo-GTO.png"
                        height="110px"
                        max-width="110px"
                        contain
                        class="mx-auto mt-6"
                      ></v-img>
                    </v-col>
                    <v-divider vertical class="my-10"></v-divider>
                    <v-col class="col-6 col-lg-3">
                      <v-img
                        src="../assets/pic/org/logo-FIATA.png"
                        height="160px"
                        max-width="150px"
                        contain
                        class="mx-auto"
                      ></v-img>
                    </v-col>
                    <v-divider vertical class="my-10"></v-divider>
                    <v-col class="col-6 col-lg-3">
                      <v-img
                        src="../assets/pic/org/logo-MUF.png"
                        height="160px"
                        max-width="150px"
                        contain
                        class="mx-auto"
                      ></v-img>
                    </v-col>
                    <v-divider vertical class="my-10"></v-divider>
                    <v-col class="col-6 col-lg-3">
                      <v-img
                        src="../assets/pic/org/logo-AFBN.png"
                        height="160px"
                        max-width="150px"
                        contain
                        class="mx-auto"
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </section>
    </div>
    <div class="contact-bg">
      <section id="contact">
        <v-container class="py-16">
          <h1
            class="wow fadeInLeft text-h4 font-weight-bold mb-12 text-start"
            data-wow-delay="0.1s"
          >
            {{ $t("contact") }}
            <span class="text-h6 title-decorate" style="color: #c83635">■</span>
          </h1>
          <v-img
            src="../assets/plane.png"
            lazy-src="../assets/plane.png"
            contain
            width="450"
            class="contact-plane"
          ></v-img>
          <v-row class="d-flex align-center justify-space-around pt-md-16">
            <v-col cols="auto" class="mr-auto"> </v-col>
            <v-col cols="12" md="11" lg="6" xl="5">
              <v-row>
                <v-col cols="12" sm="6" md="10" lg="12">
                  <v-card
                    class="wow fadeInRight rounded-0 ms-md-7 ms-0 px-2"
                    height="100%"
                  >
                    <v-card-title class="pb-3">
                      <v-img
                        src="../assets/locate-2.png"
                        max-width="28px"
                        contain
                      ></v-img>
                      <span class="text-h6 font-weight-bold ms-1 mt-3">{{
                        $t("Headquarters")
                      }}</span
                      ><br />
                    </v-card-title>
                    <p
                      class="ps-md-10 px-3 pb-4 text-body-1 font-weight-medium"
                    >
                      <span
                        >{{ $t("address") }}：{{ $t("Taipei_address") }}</span
                      ><br />
                      <span>{{ $t("phone") }}：+ 886 2 2509 5522 </span><br />
                      <span>{{ $t("fax") }}：+ 886 2 2509 5525 </span><br />
                      <span>{{ $t("email") }}：richard@solarglobal.com.tw</span
                      ><br />
                    </p>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="10" lg="12">
                  <v-card
                    class="wow fadeInRight rounded-0 me-md-7 me-0 px-2"
                    height="100%"
                    data-wow-delay="0.1s"
                  >
                    <v-card-title class="pb-3">
                      <v-img
                        src="../assets/locate-1.png"
                        max-width="28px"
                        contain
                      ></v-img>
                      <span class="text-h6 font-weight-bold mt-3">{{
                        $t("Shanghai")
                      }}</span
                      ><br />
                    </v-card-title>
                    <p
                      class="ps-md-10 px-3 pb-4 text-body-1 font-weight-medium"
                    >
                      <span
                        >{{ $t("address") }}：{{ $t("Shangha_address") }}</span
                      ><br />
                      <span
                        >{{ $t("phone") }}：+ 86 21 65752450 | 65752481 |
                        65752951 | 65752963 </span
                      ><br />
                      <span>{{ $t("fax") }}：+ 86 21 65753245</span><br />
                      <span
                        >{{ $t("email") }}：jane-sha@solardragon-china.com</span
                      >
                    </p>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="10" lg="12">
                  <v-card
                    class="wow fadeInRight rounded-0 ms-md-7 ms-0 px-2"
                    height="100%"
                    data-wow-delay="0.15s"
                  >
                    <v-card-title class="pb-3">
                      <v-img
                        src="../assets/locate-3.png"
                        max-width="28px"
                        contain
                      ></v-img>
                      <span class="text-h6 font-weight-bold ms-1 mt-3">{{
                        $t("Shenzhen")
                      }}</span
                      ><br />
                    </v-card-title>
                    <p
                      class="ps-md-10 px-3 pb-4 text-body-1 font-weight-medium"
                    >
                      <span
                        >{{ $t("address") }}：{{ $t("Shenzhen_address") }}</span
                      ><br />
                      <span>{{ $t("phone") }}：+ 86 755 25635046</span><br />
                      <span>{{ $t("fax") }}：+ 86 755 25635049</span><br />
                      <span
                        >{{ $t("email") }}：lily-szn@solardragon-china.com</span
                      >
                    </p>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="10" lg="12">
                  <v-card
                    class="wow fadeInRight rounded-0 me-md-7 me-0 px-2"
                    height="100%"
                    data-wow-delay="0.2s"
                  >
                    <v-card-title class="pb-3">
                      <v-img
                        src="../assets/locate-4.png"
                        max-width="28px"
                        contain
                      ></v-img>
                      <span class="text-h6 font-weight-bold ms-1 mt-3">{{
                        $t("Singapore")
                      }}</span
                      ><br />
                    </v-card-title>
                    <p
                      class="ps-md-10 px-3 pb-4 text-body-1 font-weight-medium"
                    >
                      <span
                        >{{ $t("address") }}： NO.21,Toh Guan Road East, #07-03
                        Toh Guan Centre, Singapore 608609 </span
                      ><br />
                      <span>{{ $t("phone") }}：+65 6476 3316</span><br />
                      <span>{{ $t("fax") }}：+65 6475 3316</span><br />
                      <span
                        >{{
                          $t("email")
                        }}：phyllis@solar.com.sg、jason@solar.com.sg、steven@solar.com.sg</span
                      ><br />
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </div>

    <v-footer color="#c83635" dark>
      <div class="mx-auto">
        <span class="text-overline">
          {{ $t("copyRight") }}{{ new Date().getFullYear() }} —
          {{ $t("copyRight_componay") }}</span
        >
      </div>
    </v-footer>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
  data() {
    return {
      activeButton: "",
      showScrollup: false,
      languageList: [
        {
          value: "zh-TW",
          title: "繁體中文",
        },
        {
          value: "zh-CN",
          title: "简体中文",
        },
        {
          value: "en-US",
          title: "English",
        },
      ],
      languageIcon: "/language-icon.svg",
    };
  },
  watch: {
    "$i18n.locale"(newVal) {
      console.log(`現在使用的語言是 ${newVal}`);
      if (newVal === "tw" || newVal === "cn") {
        document.querySelector(".v-toolbar.v-toolbar--absolute").style.width =
          "720px";
        document.querySelector(".org-subtitle-bg").style.width = "80px";
        this.languageIcon = "/language-icon.svg";
      } else {
        document.querySelector(".v-toolbar.v-toolbar--absolute").style.width =
          "820px";
        document.querySelector(".org-subtitle-bg").style.width = "120px";
        this.languageIcon = "/language-icon-en.svg";
      }
    },
  },
  methods: {
    changeLanguage(index) {
      this.language = this.languageList[index];
      switch (this.language.value) {
        case "zh-TW":
          this.$i18n.locale = "tw";
          this.languageIcon = "/language-icon.svg";
          document.title = this.$t("company");
          break;
        case "zh-CN":
          this.$i18n.locale = "cn";
          this.languageIcon = "/language-icon.svg";
          document.title = this.$t("company");
          break;
        default:
          this.$i18n.locale = "en";
          this.languageIcon = "/language-icon-en.svg";
          document.title = this.$t("company");
          break;
      }
    },
    scrollToTarget(target) {
      const el = document.getElementById(target);
      if (el) {
        const element = document.getElementById(target);
        const navbarHeight = this.$refs.navbar.$el.clientHeight;
        const yOffset = navbarHeight;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset - yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.showScrollup = scrollTop > 100; // 滾動超過 100 像素時顯示 scrollup 按鈕
    },
    scrollup() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.activeButton = "";
    },
    _initSwiper() {
      let swiper = new Swiper(".swiper", {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 3,
          slideShadows: true,
        },
        autoplay: {
          delay: 3000,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          1560: {
            slidesPerView: 2,
          },
        },
      });
    },
  },

  mounted() {
    if (navigator.language === "zh-TW" || navigator.userLanguage) {
      this.$i18n.locale = "tw";
      this.language = this.languageList[0];
    } else if (navigator.language === "zh-CN" || navigator.userLanguage) {
      this.$i18n.locale = "cn";
      this.language = this.languageList[1];
    } else {
      this.language = this.languageList[2];
      this.$i18n.locale = "en";
    }

    if (this.$i18n.locale === "tw" || this.$i18n.locale === "cn") {
      this.languageIcon = "/language-icon.svg";
      document.querySelector(".v-toolbar.v-toolbar--absolute").style.width =
        "720px";
      document.querySelector(".org-subtitle-bg").style.width = "80px";
    } else {
      this.languageIcon = "/language-icon-en.svg";
      document.querySelector(".v-toolbar.v-toolbar--absolute").style.width =
        "820px";
      document.querySelector(".org-subtitle-bg").style.width = "120px";
    }
    this._initSwiper();
    window.addEventListener("scroll", this.handleScroll);
    this.$nextTick(() => {
      new this.$wow().init();
    }),
      (onload = () => {});
    const plane = document.querySelector(".contact-plane");
    const scrollTop1 = window.pageYOffset;
    plane.style.transform = `translateX(${scrollTop1 * -1}px)`;
    document.addEventListener("scroll", () => {
      const scrollTop = window.pageYOffset;
      plane.style.transform = `translateX(${-scrollTop * 0.5}px)`;
    });
  },
};
</script>
<style>
.swiper {
  width: 100%;
  overflow: hidden;
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  background: #ffffff;
}

.swiper-slide {
  height: 430px;
}

@media screen and (max-width: 767px) {
  .swiper-slide {
    height: 290px;
  }
}

.swiper-slide--one {
  background: url("../assets/about-pic1.jpg") no-repeat 50% 50% / cover;
}

.swiper-slide--two {
  background: url("../assets/about-pic2.jpg") no-repeat 50% 50% / cover;
}

.swiper-slide--three {
  background: url("../assets/about-pic3.jpg") no-repeat 50% 50% / cover;
}

.cusor-pointer {
  cursor: pointer;
}

.scrollup-btn {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 5;
}

/* 服務項目 */
.card-service {
  height: 270px;
  display: flex;
  gap: 10px;
}

.card-service .card-service-item {
  position: relative;
  flex: 1 1;
  background-size: cover;
  background-position: center;
  transition: 0.5s ease-in-out;
}

.card-service .card-service-item:nth-child(1) {
  background-image: url(../assets/service-pic1.jpg);
}

.card-service .card-service-item:nth-child(2) {
  background-image: url(../assets/service-pic2.jpg);
}

.card-service .card-service-item:nth-child(3) {
  background-image: url(../assets/service-pic3.jpg);
}

.card-service .card-service-item:nth-child(4) {
  background-image: url(../assets/service-pic4.jpg);
}

.card-service .card-service-item:hover {
  flex: 2 1;
}

.card-service-subtitle {
  position: absolute;
  top: -47px;
  width: -webkit-fill-available;
}

.card-service-subtitle-bottom {
  position: absolute;
  bottom: -63px;
  width: -webkit-fill-available;
}

/* 導行列 */
.active {
  padding-top: 5px;
  border-bottom: 2px solid white;
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 0px 0px !important;
}

.v-toolbar.v-toolbar--absolute {
  width: 820px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
}

/* banner */
.header-bg {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.banner {
  background-image: url("https://i.imgur.com/9qSQfRJ.jpg");
  background-size: cover;
  height: 70vh;
  background-position: center right;
  overflow: hidden;
  animation: scale 10s linear infinite;
}

@media screen and (max-width: 1200px) {
  .banner {
    background-image: url("../assets/banner.jpg");
    height: 70vh;
    background-position: center right;
    overflow: hidden;
    animation: scale 10s linear infinite;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.3);
  }
}

.card-slogan {
  background-color: #f2f2f2cc !important;
  position: absolute;
  bottom: 20%;
  right: 10%;
}

.card-slogan-h5 {
  background-color: rgb(255 255 255 / 0%) !important;
  position: absolute;
  bottom: 15%;
  right: 2%;
}

.card-slogan-title {
  position: relative;
}

.card-slogan-title::after {
  content: "";
  width: 80%;
  height: 1.5px;
  background: #c83635;
  position: absolute;
  left: 5px;
  bottom: -10px;
}

/* timeline */
.v-icon.v-icon::after {
  display: none;
}

.v-timeline-item__icon {
  background-color: #c83635 !important;
}

.theme--light.v-timeline .v-timeline-item__dot {
  background: #c8363552;
}

.theme--light.v-timeline::before {
  background: rgb(200 54 53 / 36%);
}

/* 標題裝飾 */
.title-decorate,
.title-decorate-vertical,
.title-decorate-org {
  position: relative;
}

.title-decorate::after {
  content: "";
  width: 100px;
  height: 2px;
  background: #c83635;
  position: absolute;
  left: 22px;
  bottom: 8px;
}

.title-decorate-org::after {
  content: "";
  width: 100px;
  height: 2px;
  background: #f0f0f0;
  position: absolute;
  left: 22px;
  bottom: 8px;
}

.title-decorate-vertical::after {
  content: "";
  width: 2px;
  height: 100px;
  margin-left: 8px;
  background: #c83635;
  position: absolute;
  left: 5px;
  top: 35px;
}

.v-btn--icon.v-size--small {
  height: 0px;
  width: 0px;
}

.mdi-square-medium::before {
  content: "\F0A13";
  color: #ff0000;
}

/* block背景 */
.main-bg {
  background-image: url("../assets/main-bg.png");
  background-position: center;
  background-size: cover;
  padding-bottom: 70px;
}

.org-bg {
  position: relative;
  background-image: url("../assets/org-bg.png");
  background-size: cover;
  margin-bottom: 70px;
  padding-bottom: 70px;
}

.org-subtitle-bg {
  background-image: url("../assets/org-subtitle-bg.png");
  background-size: contain;
  background-position-y: center;
  /* width: 80px; */
}

.org-contain {
  position: absolute;
  top: 50%;
  z-index: 3;
  width: -webkit-fill-available;
}

.contact-bg {
  position: relative;
  padding: 200px 0px;
  background-image: url("../assets/map_new.svg");
  background-position: 5%;
}

.contact-plane {
  position: absolute;
  rotate: 8deg;
  bottom: 25%;
  right: 5%;
}

@media screen and (max-width: 1900px) {
  .contact-plane {
    right: -25%;
  }
}

@media screen and (max-width: 960px) {
  .contact-bg {
    padding: 510px 0px 120px 0px;
    background: linear-gradient(#ffffff, #f4eddf);
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .contact-bg {
    padding: 340px 0px 120px 0px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1264px) {
  .contact-bg {
    padding: 320px 0px;
    background: linear-gradient(#ffffff, #f4eddf);
  }
}
</style>
